* {
  font-family: 'ITC Avant Garde', 'Franklin Gothic Medium', Tahoma, sans-serif;
}
body {
  margin: 0px;
}
button,
input,
a,
button:active,
input:active,
a:active,
button:focus,
input:focus,
a:focus {
  outline: none !important;
}
#app {
  clear: both;
}

.markerlabel {
  padding-bottom: 14px;
  box-sizing: border-box;
}
