@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i&display=swap');
@import url('/themes/dollar/assets/dollar-fonts/stylesheet.css');
@import url('/themes/dollar/assets/dollar-fonts/stylesheet.css');
@import '~tippy.js/dist/tippy.css';
@import './app';
@import './app';
@import './nav';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-multi-carousel/lib/styles.css';

.react-toast-notifications__container {
  z-index: 9999 !important;
}
